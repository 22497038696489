.footer-mob {
  position: fixed;
  bottom: 0;
  /* height: calc(100vh - 120px); */
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: white;
  color: white;
  text-align: left;
  margin-top: 40px;
  z-index: 9998;
  overflow: hidden;
}

.footer-mob__item {
  display: flex;
  align-items: center;
  background: #F3F3F3;
  padding: 20px;
  width: 100%;
  margin: 10px 0px;
}

.footer-mob__item-close {
  width: 60px;
  background: #F3F3F3;
  margin-top: 20px;
  padding: 20px;
}

.footer-mob__text {
  color: #292283;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px; /* 207.692% */
  letter-spacing: 1.3px;
  margin-left: 10px;
}


@media screen and (min-width: 768px) {
  .footer-mob {
    display: none;
  }
}
