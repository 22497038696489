.review__text {
  color: #777;
  line-height: 30px;
  margin: 40px 0px;
}

.slider__content img {
  width: 100%;
  height: 475px;
  border-radius: 10px;
}


.personal-card__recom {
  width: 95%;
  margin: 40px auto;
}

.slider__content {
  position: relative;
  margin-top: 30px;
}

.slider__content-text {
  position: absolute;
  top: 40px;
  left: 40px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.slider__content-head {
  color: white;
  width: 100%;
}

.slider__content-desc {
  width: 650px;
  color: white;
  margin-top: 30px;
}

.personal-card__recom-text {
  color: #292283;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.personal-card {
  background: #F8F8F8;
  padding: 40px 0px;
}

@media only screen and (max-width: 768px) {
  .review__text {
    font-size: 0.8rem;
    margin: 20px 0px;
  }

  .slider__content img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .slider__content-desc {
    width: 100%;
  }

  .none {
    display: none;
  }

  .slider__content h6 {
    font-size: 0.8rem;
  }
}
