.feature__subtitle {
  color: #df2020;
}

.feature__title span {
  color: #df2020;
}

.feature__text {
  color: #777;
}

.feature__item p {
  color: #777;
}
.food__block {
  padding: 0 !important;
}

.food__category {
  text-align: center;
  padding: 0px 0px 20px 20px;
  justify-content: center !important;
}

.food__category button {
  color: #212e45;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: none;
  outline: none;
  padding: 7px 20px;
  background: transparent;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  border-bottom: 2px solid transparent;
}
.main__head {
  color: #f5f5f5;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  text-align: left;
  border-left: 6px solid #e21d23;
  padding-left: 10px;
  margin-bottom: 80px;
}

.delimiter {
  width: 100%;
  height: 54px;
  background: #f3f3f3;
  margin: 60px 0px;
}

.popylar__card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.main__link a:hover {
  color: black;
}
.main__head-span {
  color: #e21d23;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
}

.foodBtnActive {
  border-bottom: 3px solid #B0004C !important;
}
.no-product {
  color: white;
  height: 50vh;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
}
.tasty__treat-title span {
  color: #df2020;
}

.tasty__treat-desc {
  color: #777;
  line-height: 28px;
}

.choose__us-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.choose__us-title i {
  font-weight: 500 !important;
  color: #df2020;
}

.choose__us-desc {
  color: #777;
}

.testimonial__subtitle {
  color: #df2020;
}

.testimonial__title span {
  color: #df2020;
}

.testimonial__desc {
  color: #777;
  line-height: 30px;
}

.home-img {
  width: 100%;
}
.home-img img {
  width: 100%;
}



@media only screen and (max-width: 768px) {
  .hero__content h5 {
    font-size: 1rem;
  }

  .hero__content p {
    font-size: 1.5rem;
    line-height: 25px;
  }

  .hero__title {
    font-size: 1.5rem;
    line-height: 40px;
  }

  .hero__btns button {
    padding: 5px 15px;
    font-size: 0.7rem;
  }

  .hero__service p {
    font-size: 0.7rem !important;
  }

  .feature__item h5 {
    font-size: 0.9rem;
  }

  .feature__item p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .feature__subtitle {
    font-size: 1rem;
  }
  .feature__text {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  .food__category {
    padding: 15px;
  }
  .food__category button {
    padding: 5px 15px;
    font-size: 0.7rem;
  }

  .tasty__treat-desc {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  .choose__us-title {
    font-size: 0.8rem;
  }

  .choose__us-desc {
    font-size: 0.7rem;
  }

  .testimonial__subtitle {
    font-size: 1rem;
  }
  .testimonial__desc {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 576px) {
  .hero__content {
    text-align: center;
  }

  .hero__btns {
    justify-content: center;
  }

  .hero__service {
    justify-content: center;
  }
  .why__choose-us {
    padding-top: 0 !important;
  }
}
