
@keyframes loading-dots {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.loading-dots {
    animation: loading-dots 1s infinite;
}
.loading-dots2 {
    animation: loading-dots 1s infinite;
    animation-delay: 0.33s; /* Задержка на треть секунды */
}
.loading-dots3 {
    animation: loading-dots 1s infinite;
    animation-delay: 0.66s; /* Две трети секунды */
}