.cart__item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  background: white;
  padding: 30px 20px;
}
.cart__product-info {
  width: 100%;
  margin-left: 50px;
}

.cart__product-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart__product-quantity {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 40px 0px 20px 0px;
  border-bottom: 2px solid #b0004c;
  padding-bottom: 20px;
}
.cart__item-info-img {
  width: 220px;
  height: 300px;
}
.cart__product-quantity-box {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 120px;
  margin-left: 30px;
  border: 2px solid #b0004c;
  padding: 0px 20px;
}

.cart__product-box-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart__product-title {
  margin: 0;
  color: #212e45;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}

.cart__product-price-text {
  color: #212e45;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.cart__product-quantity-text {
  color: #212e45;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.cart__product-item-size {
  display: flex;
  align-items: center;
}

.cart__product-item-size-text {
  color: #212e45;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  margin-right: 25px;
}

.cart__product-item-size-select {
  color: #b0004c;
  padding: 5px 10px;
  border: 2px solid #b0004c;
  outline: none;
}

.cart__product-price {
  color: #b0004c;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.quantity {
  color: #212e45;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
}

.increase__decrease-btn {
}

.increase__btn,
.decrease__btn {
  cursor: pointer;
  color: #212e45;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
}

.delete__btn {
}

.cart__product-delete-btn-text {
  color: #212e45;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  margin-left: 15px;
  cursor: pointer;
}

.cart__product-delete-btn {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 576px) {
  .delete__btn {
    font-size: 1rem;
  }
}
