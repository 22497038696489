.delivery-main {
  background-image: url(../assets/images/delivery-bg__main.png);
  background-size: 100% 100%;
  height: 100vh;
  padding: 60px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.delivery-main__items {
  position: relative;
}

.delivery-main__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.delivery-main__items__number {
  color: rgba(176, 0, 76, 0.5);
  position: absolute;
  left: -28px;
  font-size: 107px;
  font-style: normal;
  font-weight: 900;
  top: -80px;
  z-index: -1;
}

.delivery-main__items__desc {
  color: #b0004c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}
.delivery-main__head {
  color: rgba(33, 46, 69, 0.84);
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 3px;
}

.delivery-decor {
  text-align: center;
  background-image: url(../assets/images/delivery-bg.png);
  background-size: 100% 100%;
  padding: 40px 0px 140px 0px;
}

.delivery-decor__head {
  color: #212e45;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  margin: 40px 0px 20px 0px;
}
.delivery-decor__descr {
  color: #b0004c;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
  .delivery-main__box {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
  .delivery-main__items__number {
    font-size: 36px;
    top: 0;
    z-index: 99;
  }
  .delivery-main__items__desc {
    font-size: 14px;
  }
  .delivery-main {
    height: calc(100vh - 182px);
    padding: 0px 0px 20px;
  }
  .delivery-main__head {
    font-size: 32px;
  }
  .delivery-main {
    display: none;
    background-image: none !important;
  }
  .delivery-decor__head {
    margin: 0;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 15px;

  }
  .delivery-decor {
    padding-top: 10px;
  }
  .delivery-decor__descr {
    line-height: 30px;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
  }
  .delivery-decor__descr br {
    display: none;
  }
}