.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 60px 0px;
}

.video-play {
  width: 100%;
  height: 450px;
}

@media screen and (max-width: 768px) {
  .video-play {
    height: 300px;
  }
}
