.favorites-head {
    color: #292283;
    text-align: center;
    margin-top: 20px;
    border-bottom: 1px solid #292283;
    padding-bottom: 10px;
}

.favorites-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

ul {
    margin: 0px !important;
    padding: 0 !important;
}

.favorites-title {
    font-size: 46px;
    text-align: center;
    padding: 40px;
    color: #292283;
    
}

@media screen and (max-width: 768px) {
    .favorites-title {
        font-size: 24px;
        font-weight: 600;
    }
    .favorites-block {
        justify-content: center;
        margin-bottom: 72px !important;
    }
}